<template>
  <Fragment>
    <div class="box has-max-width">
      <h3 class="title is-5 push-bottom-xxs">{{ $t('profile.account.whoAreYou.title') }}</h3>
      <p v-html="$t('profile.account.whoAreYou.helpText')"></p>

      <form v-if="isPopulated" @submit.prevent="update">
        <div class="field is-grouped">
          <div class="control">
            <label class="label">{{ $t('general.sex') }}</label>

            <div class="select select-gender">
              <select v-model="sex" :disabled="isLoading">
                <option :value="null">{{ $t('general.notListed') }}</option>
                <option value="male">{{ $t('general.male') }}</option>
                <option value="female">{{ $t('general.female') }}</option>
              </select>
            </div>
          </div>

          <div class="control">
            <label class="label">{{ $t('profile.account.whoAreYou.born') }}</label>

            <div class="select select-born">
              <select v-model="yearOfBirth" :disabled="isLoading">
                <option :value="null">{{ $t('general.notListed') }}</option>
                <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="box has-max-width">
      <form v-if="isPopulated" @submit.prevent="update">
        <h4 class="title is-6">{{ $t('profile.account.whoAreYou.roles') }}</h4>

        <FormField>
          <Checkbox
            v-model="roles.leader"
            :disabled="isLoading"
            :label="$t('profile.account.whoAreYou.leader')">
          </Checkbox>
        </FormField>

        <FormField>
          <Checkbox
            v-model="roles.stand"
            :disabled="isLoading"
            :label="$t('profile.account.whoAreYou.stand')">
          </Checkbox>
        </FormField>

        <FormField>
          <Checkbox
            v-model="roles.stalker"
            :disabled="isLoading"
            :label="$t('profile.account.whoAreYou.stalker')">
          </Checkbox>
        </FormField>

        <FormField>
          <Checkbox
            v-model="roles.carrion"
            :disabled="isLoading"
            :label="$t('profile.account.whoAreYou.carrion')">
          </Checkbox>
        </FormField>

        <FormField>
          <Checkbox
            v-model="roles.handler"
            :disabled="isLoading"
            :label="$t('profile.account.whoAreYou.handler')">
          </Checkbox>
        </FormField>

        <FormField>
          <Checkbox
            v-model="roles.agent"
            :disabled="isLoading"
            :label="$t('profile.account.whoAreYou.agent')">
          </Checkbox>
        </FormField>

        <FormField>
          <Checkbox
            v-model="roles.trackingHunter"
            :disabled="isLoading"
            :label="$t('profile.account.whoAreYou.trackingHunter')">
          </Checkbox>
        </FormField>

        <FormField>
          <Checkbox
            v-model="roles.butcher"
            :disabled="isLoading"
            :label="$t('profile.account.whoAreYou.butcher')">
          </Checkbox>
        </FormField>

        <FormField>
          <Checkbox
            v-model="roles.gunTech"
            :disabled="isLoading"
            :label="$t('profile.account.whoAreYou.gunTech')">
          </Checkbox>
        </FormField>

        <div class="field is-grouped push-top-xl">
          <div class="flex align-center">
            <div class="control">
              <button :class="{ 'is-loading': isLoading }" class="button is-primary">
                {{ $t('general.save') }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </Fragment>
</template>

<script>
export default {
  data () {
    return {
      isLoading: false,
      isPopulated: false,
      years: []
    }
  },

  static () {
    return {
      sex: '',
      yearOfBirth: '',
      roles: {}
    }
  },

  computed: {
    profile () {
      return this.$store.getters['profile/data']
    }
  },

  watch: {
    sex (sex) {
      console.log(sex)
    }
  },

  mounted () {
    this.populate()
    this.populateYearOfBirth()
  },

  methods: {
    populate () {
      if (this.profile) {
        this.sex = this.profile.sex
        this.yearOfBirth = this.profile.yearOfBirth
        Object.assign(this.roles, this.profile.roles)

        this.isPopulated = true
      }
    },

    async update () {
      this.isLoading = true

      try {
        await this.$store.dispatch('profile/update', {
          userId: this.profile.userId,
          sex: this.sex,
          yearOfBirth: this.yearOfBirth,
          roles: this.roles
        })
      } catch (error) {
        this.$notification.danger(this.$i18n.t('general.errorOccured'))
        console.error(error)
      }

      this.isLoading = false
    },

    populateYearOfBirth () {
      const currentYear = new Date().getFullYear()

      for (let i = currentYear - 5; i > currentYear - 100; i--) {
        this.years.push(i)
      }
    }
  }
}
</script>
